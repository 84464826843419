import React, { useState, useEffect } from 'react';
import ImageTrail from './ImageTrail';
import { motion, AnimatePresence } from 'framer-motion';
import Confetti from 'react-confetti';
import './App.css';

const questions = [
  {
    question: "DO YOU CARE ABOUT CLIMATE CHANGE?",
    noanswer: "It's a hoax m8!",
    yesanswer: "Hell yes!",
    explanationTitle: "Ever feel like climate change is too big to tackle?",
    explanation: "Here's a secret: your shopping cart is actually a climate-fighting machine! By choosing sustainable products, you're telling big companies, 'Hey, green is the new black!' The more we do this, the more our economy shifts towards planet-friendly practices. So, if you're sweating about rising temperatures, remember: your purchases pack a punch. Ready to flex those sustainable shopping muscles?",
    topic: "climate change"
  },
  {
    question: "DO YOU CARE ABOUT SOCIAL INEQUALITIES?",
    noanswer: "Nope, I mind my own business",
    yesanswer: "Sure do, we're all connected",
    explanationTitle: "Environmental problems hit some communities harder than others",
    explanation: "Building a sustainable world is like leveling the playing field. Clean energy creates jobs in struggling towns. Green spaces pop up in concrete jungles. Efficient public transport connects isolated neighborhoods. It's not just about saving the planet – it's about giving everyone a fair shot. When we champion sustainability, we're promoting equality too. They're two sides of the same coin. So, if you're fired up about social justice, you're already on Team Sustainability. Ready to build a fairer, greener world for everyone?",
    topic: "social inequalities"
  },
  {
    question: "FINISH THE SENTENCE: \"Migrants are ...\" ",
    noanswer: "welcome to my country",
    yesanswer: "not invited!!",
    explanationTitle: "Think immigration is a hot-button issue?",
    explanation: "Plot twist: your carbon footprint might be the real border control! When rising seas swallow homes and droughts kill crops, people hit the road. They move to survive. But here's the kicker: living sustainably helps stabilize the climate globally. By shrinking your carbon footprint, you're actually helping people stay put in their home countries. So, if mass migration makes you sweat, living more sustainably is your secret weapon. After all, why would people leave their countries if life is good at home?",
    topic: "migration"
  },
  {
    question: "FINISH THE SENTENCE: \"Plastic waste on a beach is ...\" ",
    noanswer: "fun! Love to play with dirty plastic bottles",
    yesanswer: "not instagrammable!! White sand beaches pls",
    explanationTitle: "Whoa there, Instagram warrior!",
    explanation: "That picture-perfect coastline isn't just about aesthetics - it's a sign of a healthy planet and the result of smart choices back home. Every sustainable swap you make is like casting a vote for cleaner coastlines worldwide. Choosing reusables over disposables? You're basically a long-distance beach cleaner. The less we buy polluting products, the less they end up trashed in the environment. So next time you're dreaming of that flawless beach selfie, remember: your everyday choices are the real filters making it possible. Ready to be an eco-influencer and keep those beaches 'gram-worthy?",
    topic: "pollution"
  },
  {
    question: "DO GLOBAL TENSIONS/CONFLICTS HAVE YOU ON EDGE?",
    noanswer: "Not really, always been this way",
    yesanswer: "Yeah, pretty nerve wracking!",
    explanationTitle: "Worried about ww3?",
    explanation: "Surprise surprise - your lifestyle might be the ultimate peace treaty! When resources run dry, people don't share - they square up. I mean, just remember these videos of people fighting over toilet paper in Covid times... Here's the deal, the more resources we consume, the more tensions increase around what's left. Living more sustainably, buying second hand, re-purposing, sharing etc is about doing more with what we already have. Living sustainably is real diplomacy via lifestyle! So, if global tensions make you sweat, sustainability is your secret weapon. After all, it's hard to pick fights when everyone's needs are met. Ready to save the world from your living room?",
    topic: "conflicts"
  },
  {
    question: "IS YOUR WALLET FEELING THE PINCH LATELY?",
    noanswer: "Nope, money grows on my trees",
    yesanswer: "Every penny counts now!",
    explanationTitle: "Life's expensive these days, omg!",
    explanation: "Hold up, because sustainable living might be your financial lifesaver. It's like extreme couponing for cool kids. Public transport? Watch your budget grow faster than rush hour traffic. Thrift store fashion? Designer looks without the designer debt. Repair that phone instead of upgrading? You're basically printing money. Meal prep with local produce? Watch those takeout costs disappear faster than free samples at Tesco. So if inflation's got you sweatin', sustainability might be your secret weapon. Ready to fall in love with your bank statement again?",
    topic: "your purchasing power"
  },
  {
    question: "HOW MUCH DO YOU CARE ABOUT YOUR HEALTH?",
    noanswer: "YOLO, who cares?",
    yesanswer: "Health is wealth!",
    explanationTitle: "Feeling more slug than human lately?",
    explanation: "Surprise, sustainable living could be your personal trainer! It's like a two-for-one deal: what's good for the planet often rocks your health too. Bike to work and get your heart do a happy dance. Ditch the processed junk for local veggies and watch your energy skyrocket faster than a SpaceX launch. Swap chemicals for natural products and your skin will throw a glow-up party. Think about it, if we eat, dress and breathe bad stuff, how's that supposed to make us more healthy? Turns out, what's good for the environment is often good for us too. Who would have thought saving the planet could be your wellness secret weapon?",
    topic: "your health"
  },
];

const sustainabilityTips = [
  { name: "Ganddee", description: "The best way to find second hand shops, vegan food places and save money by shopping sustainably in the UK. Think about it as the 'Google Maps of sustainable shops' with exclusive promo codes for select shops.", url: "https://ganddee.com" },
  { name: "Freegle", description: "A cool website that lets you connect with neighbors to donate items, reducing waste. Ideal to get good stuffs for free. If that sounds great (which it is!) also check the 'Olio' app - similar concept but also with food!", url: "https://www.ilovefreegle.org/" },
  { name: "TooGoodToGo", description: "The OG of food saving. This app helps you get surplus meals from restaurants for cheap. It's simple, if a bakery has lots of pastries left 1h before it closes, they'll sell 'surplus bags' on the app and you'll be be able to snap this food for cheap!", url: "https://toogoodtogo.com" },
  { name: "Library of Things", description: "A set of libraries (places where you can borrow/rent stuffs) except the items you rent aren't books but tools and other things you usually only need occasionally (e.g. a drill). LoTs save you useless expensive purchases and clutter from this you only need once.", url: "https://libraryofthings.co.uk" },
  { name: "More", description: "We have so many more tips to save you money and help you live more sustainably. Check ganddee.com for more.", url: "https://ganddee.com" }
];

function App() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showExplanation, setShowExplanation] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [swipe, setSwipe] = useState({ x: 0, y: 0, rotation: 0, opacity: 1 });
  const [answers, setAnswers] = useState([]);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [gameStarted, setGameStarted] = useState(false);

  const handleAnswer = (isYes) => {
    const direction = isYes ? 1 : -1;
    setSwipe({ x: direction * window.innerWidth, y: 0, rotation: direction * 60, opacity: 0 });
    setAnswers([...answers, isYes]);

    if (isYes) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
        setShowExplanation(true);
      }, 500);
    } else {
      setTimeout(() => {
        nextQuestion();
      }, 300);
    }
  };

  const nextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setSwipe({ x: 0, y: 0, rotation: 0, opacity: 1 });
    setShowExplanation(false);
  };

  const restartGame = () => {
    setCurrentQuestionIndex(0);
    setAnswers([]);
    setShowExplanation(false);
    setSwipe({ x: 0, y: 0, rotation: 0, opacity: 1 });
  };

  const handleTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe || isRightSwipe) {
      handleAnswer(isRightSwipe);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'ArrowLeft') {
        handleAnswer(false);
      } else if (event.key === 'ArrowRight') {
        handleAnswer(true);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const currentQuestion = questions[currentQuestionIndex];
  const nextQuestionData = questions[currentQuestionIndex + 1];

  const renderStartCard = () => (
    <div className="thank-you-container">
      <h2>Let's pop the "eco" bubble!</h2>
      <p>"Sustainability this, sustainable that" - why should I feel concerned by this thing?</p>
      <div className="sustainability-tips">
        <h3>Let's find out...</h3>
        <p>Take 15 seconds to answer a few questions. Let's figure out if there's something you can get out of this "green" trend.</p>
      </div>
      <button onClick={() => setGameStarted(true)} className="start-button">Start</button>
    </div>
  );

  const renderCard = (question, index) => (
    <motion.div
      key={index}
      className="card"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      initial={index === currentQuestionIndex ? { scale: 1 } : { scale: 0.95 }}
      animate={{
        x: index === currentQuestionIndex ? swipe.x : 0,
        y: index === currentQuestionIndex ? swipe.y : 0,
        rotate: index === currentQuestionIndex ? swipe.rotation : 0,
        opacity: index === currentQuestionIndex ? swipe.opacity : 1,
        scale: index === currentQuestionIndex ? 1 : 0.95,
        zIndex: questions.length - index
      }}
      exit={{ x: swipe.x * 2, y: swipe.y, opacity: 0, transition: { duration: 0.2 } }}
      transition={{ type: 'spring', damping: 20, stiffness: 200 }}
    >
      <div className="card-content">
        <h2>{question.question}</h2>
        <div className="button-container">
          <button onClick={() => handleAnswer(false)} className="no-button">{question.noanswer}</button>
          <button onClick={() => handleAnswer(true)} className="yes-button">{question.yesanswer}</button>
        </div>
      </div>
    </motion.div>
  );

  const renderThankYouScreen = () => {
    const yesAnswers = answers.reduce((acc, answer, index) => {
      if (answer && index < questions.length) {
        acc.push(questions[index].topic);
      }
      return acc;
    }, []);
  
    return (
      <motion.div
        key="thank-you"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="thank-you-container"
      >
        <h2>Thank you for participating!</h2>
        {yesAnswers.length === 0 ? (
          <p>Oh snap! Looks like living more sustainably really isn't for you. We really didn't think it was possible to get there 😅. Well, thanks for answering the questions! Have a great day, friend.</p>
        ) : (
          <>
            <p>Nice! Looks like you care about: {yesAnswers.join(', ')}. Following a sustainable lifestyle by buying second-hand items, limiting the purchase of hyper packaged & processed food, etc will be beneficial to you!</p>
            <div className="sustainability-tips">
              <h3>Here are tools to get you started saving money and live more sustainably</h3>
              <ul>
                {sustainabilityTips.map((tip, index) => (
                  <li key={index}>
                    <strong>
                      <a href={tip.url} target="_blank" rel="noopener noreferrer">
                        {tip.name}
                      </a>
                    </strong>
                    : {tip.description}
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
        <button onClick={restartGame} className="restart-button">Restart</button>
      </motion.div>
    );
  };

  return (
    <div className="App">
      <div className="background-canvas">
        <ImageTrail imageSources={['/guineapig.png', '/dog.png', '/cat.png', '/monkey.png']} size={100} interval={1}/>
      </div>
      <div className="content-wrapper">
        {showConfetti && <Confetti />}
        <header className="App-header">
          <a href="https://ganddee.com" target="_blank" rel="noopener noreferrer">
            <img src="/GanddeeLongLogo.png" alt="Ganddee Logo" className="header-logo" />
          </a>
        </header>
        <main className="main-content">
          {!gameStarted ? (
            renderStartCard()
          ) : currentQuestionIndex < questions.length && !showExplanation ? (
            <div className="card-container">
              <AnimatePresence>
                {renderCard(currentQuestion, currentQuestionIndex)}
                {nextQuestionData && renderCard(nextQuestionData, currentQuestionIndex + 1)}
              </AnimatePresence>
            </div>
          ) : showExplanation ? (
            <motion.div
              key="explanation"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              className="explanation-container"
            >
              <h2>{currentQuestion.explanationTitle}</h2>
              <p className="explanation">{currentQuestion.explanation}</p>
              <button onClick={nextQuestion} className="next-button">
                {currentQuestionIndex < questions.length - 1 ? "Next" : "Finish"}
              </button>
            </motion.div>
          ) : (
            renderThankYouScreen()
          )}
        </main>
        <footer>
  <div className="footer-content">
    <div className="social-icons">
      <a href="https://www.tiktok.com/@ganddeeapp" target="_blank" rel="noopener noreferrer">
        <img src="/tiktok-icon.webp" alt="TikTok" className="social-icon" />
      </a>
      <a href="https://www.instagram.com/ganddeeapp" target="_blank" rel="noopener noreferrer">
        <img src="/instagram-icon.webp" alt="Instagram" className="social-icon" />
      </a>
    </div>
    <a href="https://www.ganddee.com" target="_blank" rel="noopener noreferrer" className="powered-by">
      Powered by <img src="/GanddeeLongLogo.png" alt="Ganddee" className="ganddee-logo" />
    </a>
  </div>
</footer>
      </div>
    </div>
  );
}

export default App;