import React, { useEffect, useRef } from 'react';

const ImageTrail = ({ imageSources, size = 32, interval = 10 }) => {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const imagesRef = useRef([]);
  const trailPositionsRef = useRef([]);
  const lastRenderTimeRef = useRef(0);
  const animationFrameRef = useRef(null);
  const currentImageIndexRef = useRef(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    contextRef.current = canvas.getContext('2d');
    
    const loadImage = (src) => {
      return new Promise((resolve) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.src = src;
      });
    };

    const loadImages = async () => {
      imagesRef.current = await Promise.all(imageSources.map(src => loadImage(src)));
      startDrawing();
    };

    loadImages();

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const updateTrail = (x, y) => {
      trailPositionsRef.current.push({ x, y, imageIndex: currentImageIndexRef.current });
    };

    const drawImages = () => {
      const ctx = contextRef.current;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      trailPositionsRef.current.forEach((pos) => {
        const image = imagesRef.current[pos.imageIndex];
        if (image) {
          ctx.drawImage(image, pos.x - image.width / 2, pos.y - image.height / 2);
        }
      });
    };

    const startDrawing = () => {
      const animate = (time) => {
        if (time - lastRenderTimeRef.current >= interval) {
          drawImages();
          lastRenderTimeRef.current = time;
        }
        animationFrameRef.current = requestAnimationFrame(animate);
      };
      animate();
    };

    const handleMouseMove = (e) => {
      updateTrail(e.clientX, e.clientY);
    };

    const handleClick = () => {
      currentImageIndexRef.current = (currentImageIndexRef.current + 1) % imageSources.length;
    };

    window.addEventListener('resize', resizeCanvas);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('click', handleClick);
    resizeCanvas();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('click', handleClick);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [imageSources, interval]);

  return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, pointerEvents: 'none', zIndex: 9999 }} />;
};

export default ImageTrail;